/* color variables */
:root {
  --color-text: #ffffff;
  --color-text-dark: #87888F;
  --color-text-black: #000000;
  --color-primary: #64FFDA;
  --color-primary-dark: #63e7c849;
  --color-card: #2C2D31;
  --color-card-hover: #3a3c41;
  --color-bg-light: #2D2E32;
  --color-bg-dark: #25262A;
  --color-header-scroll: #232427;
  --color-header-shadow: rgba(57, 63, 72, 0.5); }

/* selection styles */
::selection {
  background: var(--color-primary); }

::-moz-selection {
  background: var(--color-primary); }

/* scrollbar styles */
::-webkit-scrollbar {
  width: 10px; }

::-webkit-scrollbar-track {
  background-color: #e2e2e2;
  -webkit-border-radius: 1px;
  border-radius: 1px; }

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 1px;
  border-radius: 1px;
  background: #6d6d6d; }

@media only screen and (max-width: 36.5rem) {
  ::-webkit-scrollbar {
    display: none; } }

/* main styles */
* {
  margin: 0;
  padding: 0; }

*,
*::before,
*::after {
  box-sizing: inherit; }

html {
  font-size: 62.5%; }

body {
  box-sizing: border-box; }

.section__label, .text__code, .btn__primary, .biography__tech--item-title, .biography__tech--item-list, .contact__label, .header__nav, .mobile__sidebar--icon, .mobile__sidebar--menu a, .jumbotron__label, .jumbotron__intro, .projects__label, .projects__item--name, .console__body--results {
  font-family: "Ubuntu Mono", monospace;
  color: var(--color-primary); }

.footer__logo, .header__logo {
  font-family: "Roboto", sans-serif; }

.btn__primary, .biography__subheading p a, .header__nav--item, .mobile__sidebar--menu a {
  text-decoration: none;
  color: var(--color-primary); }

.header__logo, .mobile__sidebar, .projects__item, .console__body {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */ }

.bold {
  font-weight: 500; }

.x-bold {
  font-weight: 700; }

.xl-bold {
  font-weight: 800; }

body {
  color: var(--color-text);
  font-size: 1.75rem; }

.btn {
  display: block;
  border: 1px solid var(--color-primary);
  border-radius: 5px; }
  .btn__primary {
    padding: 1.5rem 2rem;
    margin-top: 3rem; }
    .btn__primary--outline {
      background-color: transparent; }
    .btn__primary--outline:hover {
      background-color: var(--color-primary-dark);
      cursor: pointer; }
    @media only screen and (max-width: 43.75em) {
      .btn__primary {
        padding: 1rem 1.5rem; } }

.biography__section {
  background-color: var(--color-bg-dark); }

.biography__container {
  margin: 0 auto;
  padding: 10rem 0 0 0;
  display: flex;
  flex-direction: row; }

.biography__block {
  flex: 1 1 0;
  width: 0; }
  .biography__block--console {
    display: flex;
    justify-content: center;
    align-items: center; }

.biography__intro {
  font-size: 1.75rem;
  margin-top: 1.5rem; }

.biography__subheading {
  margin-top: 1rem; }
  .biography__subheading p {
    color: var(--color-text-dark); }
  .biography__subheading p:not(:first-child) {
    margin-top: 1rem; }

.biography__tech {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 0 3rem; }
  .biography__tech--card {
    width: 80%;
    padding: 4rem 5rem;
    background-color: var(--color-card);
    box-shadow: 0px 0px 15px 5px var(--color-shadow-light);
    border-radius: 5px; }
    @media only screen and (max-width: 68.75rem) {
      .biography__tech--card {
        width: 100%; } }
  .biography__tech--heading {
    font-size: 1.25rem;
    color: var(--color-text-dark); }
  .biography__tech--item-title {
    font-size: 1.75rem; }
  .biography__tech--item-title:not(:last-child) {
    margin-top: 1rem; }
  .biography__tech--item-list {
    margin-left: 3rem;
    font-size: 1.5rem;
    padding: .5rem 1rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(3rem, 1fr));
    row-gap: .5rem; }
    .biography__tech--item-list li {
      display: inline-block; }
    @media only screen and (max-width: 68.75rem) {
      .biography__tech--item-list {
        grid-template-columns: repeat(auto-fit, minmax(5rem, 12rem)); } }
  @media only screen and (max-width: 68.75rem) {
    .biography__tech {
      margin-top: 5rem; } }

@media only screen and (max-width: 68.75rem) {
  .biography__section {
    padding-top: 0rem; }
  .biography__container {
    flex-direction: column;
    align-items: center; }
  .biography__block {
    width: 100%; }
    .biography__block--console {
      padding: 5rem 0; } }

@media only screen and (max-width: 56.25em) {
  .biography__container {
    padding-bottom: 2rem; } }

@media only screen and (max-width: 43.75em) {
  .biography__container {
    padding-top: 7rem;
    padding-bottom: 1rem; }
  .biography__intro {
    font-size: 1.7rem; }
  .biography__subheading {
    font-size: 1.6rem; } }

.contact {
  background-color: var(--color-bg-light); }
  .contact__container {
    margin: 0 auto;
    padding: 5rem 0; }
  .contact__subheading {
    color: var(--color-text-dark);
    margin-top: 1.5rem; }
  .contact__description {
    color: var(--color-text);
    margin-top: 1rem; }
  .contact__btn {
    display: block;
    text-decoration: none;
    margin-top: 2rem; }
  @media only screen and (max-width: 56.25em) {
    .contact__description {
      font-size: 1.65rem; } }
  @media only screen and (max-width: 43.75em) {
    .contact__description {
      font-size: 1.6rem; } }

.footer__container {
  background-color: var(--color-bg-light);
  display: flex;
  justify-content: center;
  padding-top: 2rem;
  padding-bottom: 5rem; }

.footer__nav {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center; }

.footer__logo {
  font-size: 2rem; }
  .footer__logo--bold {
    font-weight: 800; }

.footer__socials {
  margin-top: 2rem; }
  .footer__socials a:not(:first-child) {
    margin-left: 1.5rem; }

.header__container {
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 150;
  height: 7vh;
  width: 100%;
  padding: 0 5rem;
  color: var(--color-text);
  background-color: var(--color-bg-light); }
  .header__container--scroll {
    opacity: 0.995;
    background-color: var(--color-header-scroll);
    -webkit-box-shadow: 0 3px 5px var(--color-header-shadow);
    -moz-box-shadow: 0 3px 5px var(--color-header-shadow);
    box-shadow: 0 3px 5px var(--color-header-shadow); }

.header__logo {
  font-size: 2.5rem; }

.header__logo:hover {
  cursor: pointer; }

.header__nav {
  font-size: .85rem;
  letter-spacing: 1px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; }
  .header__nav--item {
    font-size: 1.5rem; }
  .header__nav--item:not(:first-child) {
    margin-left: 2rem; }
  .header__nav--socials {
    margin-left: 3rem; }
    .header__nav--socials a:not(:first-child) {
      margin-left: 1rem; }
  @media only screen and (max-width: 56.25em) {
    .header__nav {
      display: none; } }

.mobile__sidebar {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 30rem;
  z-index: 100;
  background-color: #0a0a0a;
  opacity: .9; }
  .mobile__sidebar--open {
    display: none; }
    @media only screen and (max-width: 56.25em) {
      .mobile__sidebar--open {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; } }
  .mobile__sidebar--menu {
    justify-content: space-between;
    align-items: center;
    height: 20%;
    width: 100%; }

.mobile__nav {
  display: none;
  cursor: pointer;
  z-index: 150; }
  .mobile__nav span {
    background: var(--color-primary);
    display: block;
    width: 2rem;
    height: 3px;
    border-radius: 5px;
    margin-bottom: 5px;
    -webkit-transition: all 0.5s linear;
    transition: all 0.3s linear; }
  .mobile__nav--open span:nth-child(1), .mobile__nav--open span:nth-child(3) {
    transform: translate(0px, 0px) rotate(-45deg) scalex(1.3);
    margin: 0; }
  .mobile__nav--open span:nth-child(2) {
    height: 0;
    margin: 0; }
  .mobile__nav--open span:nth-child(3) {
    transform: translate(0px, -2px) rotate(45deg) scalex(1.3); }
  .mobile__nav--socials {
    margin-top: 10rem; }
    .mobile__nav--socials a:not(:first-child) {
      margin-left: 1rem; }
  @media only screen and (max-width: 56.25em) {
    .mobile__nav {
      display: block; } }

@media only screen and (max-width: 43.75em) {
  .header__container {
    padding: 0 2rem; }
  .header__logo {
    font-size: 2rem; } }

.icons__item {
  color: var(--color-text); }

i:hover {
  cursor: pointer;
  color: var(--color-primary); }

.jumbotron__section {
  background-color: var(--color-bg-light);
  margin-top: 7vh; }

.jumbotron__container {
  margin: 0 auto;
  padding: 10rem 0; }

.jumbotron__label {
  color: var(--color-text-black);
  background-color: var(--color-primary);
  padding: .5rem 1rem;
  border-radius: 3px;
  font-size: 1.5rem; }

.jumbotron__intro {
  margin-top: 3rem; }

.jumbotron__heading-1 {
  font-size: 7rem; }

.jumbotron__heading-2 {
  font-size: 3rem; }

.jumbotron__subheading {
  color: var(--color-text-dark);
  margin-top: 2rem; }

@media only screen and (max-width: 43.75em) {
  .jumbotron__container {
    padding-bottom: 7rem; }
  .jumbotron__heading-1 {
    font-size: 5rem; }
  .jumbotron__heading-2 {
    font-size: 2.2rem; }
  .jumbotron__subheading {
    font-size: 1.5rem; } }

@media only screen and (max-width: 36.5rem) {
  .jumbotron__heading-1 {
    font-size: 4.5rem; }
  .jumbotron__heading-2 {
    font-size: 2rem; }
  .jumbotron__subheading {
    font-size: 1.6rem; } }

.projects__section {
  background-color: var(--color-bg-dark);
  padding-top: 3rem; }

.projects__container {
  margin: 0 auto;
  padding: 5rem 0 10rem 0; }

.projects__list {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
  row-gap: 2rem;
  column-gap: 2rem; }

.projects__description {
  color: var(--color-text-dark);
  margin-top: 1.5rem; }

.projects__item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--color-card);
  padding: 2rem 5rem;
  border-radius: 5px; }
  .projects__item--name {
    font-size: 2rem; }
  .projects__item--description {
    font-size: 1.35rem;
    margin-top: 1rem;
    margin-bottom: 1rem; }

.projects__item:hover {
  background-color: var(--color-card-hover); }

.projects__icon {
  margin-bottom: 0;
  width: min-content; }

@media only screen and (max-width: 56.25em) {
  .projects__container {
    padding-top: 1rem; } }

@media only screen and (max-width: 43.75em) {
  .projects__container {
    padding-top: 1rem;
    padding-bottom: 7rem; } }

@media only screen and (max-width: 36.5rem) {
  .projects__list {
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr)); } }

@media only screen and (max-width: 68.75rem) {
  .console__container {
    width: 100%; } }

.console__dots {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem 2rem;
  background-color: var(--color-card);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px; }
  .console__dots .dot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 7px;
    background-color: white; }
    .console__dots .dot:first-child {
      background-color: #ff6057; }
    .console__dots .dot:not(:first-child):not(:last-child) {
      background-color: #ffc22e; }
    .console__dots .dot:last-child {
      background-color: #28ca40; }

.console__body {
  padding: 2rem 10rem 5rem 2rem;
  background-color: var(--color-card);
  color: white;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  margin: 0 auto; }
  .console__body--heading {
    font-size: 1.55rem; }
    .console__body--heading:before {
      content: ">";
      padding-right: 15px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none; }
  .console__body--results {
    margin-left: 3rem;
    font-size: 1.5rem;
    padding: .5rem 1rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(7rem, 1fr));
    row-gap: .5rem; }
    .console__body--results li {
      display: inline-block; }
    @media only screen and (max-width: 68.75rem) {
      .console__body--results {
        grid-template-columns: repeat(auto-fit, minmax(5rem, 12rem)); } }

.biography__container, .contact__container, .jumbotron__container, .projects__container {
  width: 60%; }
  @media only screen and (max-width: 112.5em) {
    .biography__container, .contact__container, .jumbotron__container, .projects__container {
      width: 70%; } }

.btn__primary, .jumbotron__label {
  width: min-content;
  white-space: nowrap; }

.biography__tech--card, .mobile__sidebar--menu {
  display: flex;
  flex-direction: column; }

.header__container {
  display: flex;
  flex-direction: row; }

.strike {
  margin-top: 1rem;
  height: 1rem;
  width: 7rem;
  background-color: var(--color-primary); }
